import { defineMessages } from "react-intl";
export default defineMessages({
  connexion: {
    id: "a2vote.connexion",
    defaultMessage: "Login",
  },
  remember_me: {
    id: "a2vote.remember.me",
    defaultMessage: "Remember me",
  },
  failed_login: {
    id: "a2vote.failed.login",
    defaultMessage: "Failed login",
  },
  failed_check_login: {
    id: "a2vote.failed.check.login",
    defaultMessage: "Failed to check login",
  },
  login: {
    id: "a2vote.login",
    defaultMessage: "Login",
  },
  password: {
    id: "a2vote.password",
    defaultMessage: "Password",
  },
  title: {
    id: "a2vote.login.title",
    defaultMessage: "Login"
  },
  reset_password: {
    id: "a2vote.reset.password",
    defaultMessage: "Reset password ?"
  },
  reset_password_title: {
    id: "a2vote.reset.password.title",
    defaultMessage: "Resetting the password"
  },
  reset_password_error: {
    id: "a2vote.reset.password.error",
    defaultMessage: "Mail not valid"
  },
  reset_password_is_send: {
    id: "a2vote.reset.password.is.send",
    defaultMessage: "Mail is send !"
  },
  return: {
    id: "a2vote.return",
    defaultMessage: "Return"
  },
  next: {
    id: "a2vote.next",
    defaultMessage: "Next"
  },
  two_factor_auth: {
    id: "a2vote.two.factor.auth",
    defaultMessage: "Two factor auth"
  },
  two_factor_auth_confirm: {
    id: "a2vote.two.factor.auth.confirm",
    defaultMessage: "Confirm the connection thought the mail sent to you"
  },
  two_factor_auth_no_mail: {
    id: "a2vote.two.factor.auth.no.mail",
    defaultMessage: "No mail received ?"
  },
  two_factor_auth_resend: {
    id: "a2vote.two.factor.auth.resend",
    defaultMessage: "Resend mail"
  },
  secondes: {
    id: "a2vote.secondes",
    defaultMessage: "secondes"
  },
  seconde: {
    id: "a2vote.seconde",
    defaultMessage: "seconde"
  },
  resend_mail: {
    id: "a2vote.resend.mail",
    defaultMessage: "Mail has been resend !"
  },
  two_factor_auth_success: {
    id: "a2vote.two.factor.auth.success",
    defaultMessage: "Two factor authentification success"
  },
  two_factor_auth_failed: {
    id: "a2vote.two.factor.auth.failed",
    defaultMessage: "Two factor authentification failed"
  },
  two_factor_auth_check: {
    id: "a2vote.two.factor.auth.check",
    defaultMessage: " Checking two factor authentification"
  },
  resend_mail_wait: {
    id: "a2vote.resend.mail.wait",
    defaultMessage: "wait before can resend mail"
  },
  link_expired: {
    id: "a2vote.link.expired",
    defaultMessage: "Link expired"
  },
  error_encounter: {
    id: "a2vote.error.encounter",
    defaultMessage: "Error encounter, please try again"
  },
});
