import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const rotateInvert = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

const Rotate = styled.div`
  display: inline-block;
  animation: ${(props) => (props.invert ? rotateInvert : rotate)}
    ${(props) => props.speed || 2}s linear infinite;
  width: min-content;
`;

const Spinner = ({ children, invert, speed }) => {
  return (
    <Rotate invert={invert} speed={speed}>
      {children}
    </Rotate>
  );
};

Spinner.propTypes = {
  children: PropTypes.any,
  invert: PropTypes.bool,
  speed: PropTypes.number,
};

export default Spinner;
