//React
import React, { useEffect, useState } from "react";
// tools
import styled from "styled-components";
import theme from "theme";
//Redux
import { useSelector, useDispatch } from "react-redux";
//Actions
import { doLogin, checkUserFrontLogin, resetPassword } from "actions/login";
// Components
import LoginPage from "./LoginPage";
import Spinner from "components/Spinner/Spinner";
import Popup from "./popup";
//Utils
import Icon from "utils/icon";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
//Style
import { Column, SpanError } from "./style";
import { validateEmail } from "../../utils/mailValidation";

const Main = styled.main`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginInput = styled.input`
  width: calc(100% - 8px);
  border: 1px solid ${theme.darkblue};
  border-radius: 4px;
  padding: 4px;
  margin: 10px 0px;
`;

const LoginButton = styled.button`
  font-family: 'Raleway', sans-serif;
  width: 100%;
  padding: 6px 12px;
  margin: 10px 0px;
  background: ${theme.blue};
  color: white;
  font-weight: bold;
  border: 1px solid ${theme.blue};
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
`;

const ResetButton = styled(LoginButton)`
  width: 45%;
`;

const LoginTitle = styled.h1`
  font-size: 1.5em;
  font-family: 'Raleway', sans-serif;
`;

const PasswordForgot = styled.span`
  font-size: 0.9em;
  color: ${theme.darkblue};
  cursor: pointer;
`;

const ResetButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Login = () => {
  // Local state
  const [login, setLogin] = useState("");
  const [pwd, setPwd] = useState("");
  const [resetPwd, setResetPwd] = useState(false);
  const [resetMail, setResetMail] = useState("");
  const [resetMailError, setResetMailError] = useState(false);
  // const [remember, setRemember] = useState(false);
  // Bind state
  const { fetchingLogin, failedLogin, failedCheckLogin, resetPwdIsSend, twoAuth } = useSelector(
    (state) => state.user
  );
  // Bind Actions
  const dispatch = useDispatch();
  const myLogin = (login, pwd, remember) =>
    dispatch(doLogin(login, pwd, remember));
  const checkRememberUser = () => dispatch(checkUserFrontLogin());
  const resetPasswordAction = (email) => dispatch(resetPassword(email));
  //Use effect
  useEffect(() => {
    checkRememberUser();
  }, []);

  const connect = (e) => {
    e.preventDefault();
    myLogin(login, pwd);
  };

  const reset = () => {
    if(validateEmail(resetMail)){
      resetPasswordAction(resetMail);
    }
    else{
      setResetMailError(true);
    }
  };

  return (
    <Main>
      { twoAuth &&
        <Popup />
      }
      <LoginPage>
        <Column>
          {
            (
              fetchingLogin &&
                <Spinner>
                  <Icon icon="LoadingHalf" />
                </Spinner>
            ) || (
              resetPwd &&
                <div>
                  <LoginTitle>
                    <FormattedMessage {...messages.reset_password_title} />
                  </LoginTitle>
                  <LoginInput
                    type="email"
                    placeholder="Email"
                    value={resetMail}
                    onChange={(e) => setResetMail(e.target.value)}
                  />
                  {
                    resetMailError &&
                      <SpanError>
                        <FormattedMessage {...messages.reset_password_error} />
                      </SpanError>
                  }
                  {
                    resetPwdIsSend &&
                      <span>
                        <FormattedMessage {...messages.reset_password_is_send} />
                      </span>
                  }
                  <ResetButtons>
                    <ResetButton onClick={() => setResetPwd(false)} >
                      <FormattedMessage {...messages.return} />
                    </ResetButton>
                    <ResetButton onClick={() => reset()} >
                      <FormattedMessage {...messages.next} />
                    </ResetButton>
                  </ResetButtons>
                </div>
            ) || (
              <React.Fragment>
                <LoginTitle>
                  <FormattedMessage {...messages.title} />
                </LoginTitle>
                <form onSubmit={connect}>
                  <FormattedMessage {...messages.login} >
                    {
                      (msg) => (
                        <LoginInput placeholder={msg} onChange={(e) => setLogin(e.target.value)} type="email" required />
                      )
                    }
                  </FormattedMessage>
                  <FormattedMessage {...messages.password} >
                    {
                      (msg) => (
                        <LoginInput placeholder={msg} onChange={(e) => setPwd(e.target.value)} type="password" required />
                      )
                    }
                  </FormattedMessage>
                  <LoginButton type="submit">
                    <FormattedMessage {...messages.connexion} />
                  </LoginButton>
                  <PasswordForgot onClick={() => setResetPwd(true)}>
                    <FormattedMessage {...messages.reset_password} />
                  </PasswordForgot>
                </form>
              </React.Fragment>
            )
          }
          {failedLogin && (
            <SpanError>
              <FormattedMessage {...messages.failed_login} />
            </SpanError>
          )}
          {failedCheckLogin && (
            <SpanError>
              <FormattedMessage {...messages.failed_check_login} />
            </SpanError>
          )}
        </Column>
      </LoginPage>
    </Main>
  );
};

export default Login;
