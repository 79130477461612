import styled from "styled-components";
import theme from "theme";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.white};
  padding: 0 20px;
  border-left: 1px solid ${theme.lightblue};
  
  @media(max-width: 768px) {
    justify-content: center;
    border-left: none;
  }
`;

export const SpanError = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 1.1em;
  margin: 4px 0px;
  color: ${theme.red};
`;
