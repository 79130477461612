//Local
import { API_ROOT, A2VOTE_API, DOMAIN, KEY_CRYPT, A2VIEW_URL, A2VOTE, DTFA } from "utils/config";
import { handleResponse, handleCatch } from "utils/handleResponse";
import { navigate } from "gatsby";
import toast from "react-hot-toast";
import axios from "axios";
// Cookie
import Cookies from "universal-cookie";
import Encryption from "utils/Encryption";
import {getAuthorization, getApiRoot} from "utils/token";
import { PWD_RESET_TEMPLATE } from "../utils/mailTemplate";

export function doLogin(login, pwd) {
  return (dispatch) => {
    dispatch({ type: "LOGIN_START" });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ login, pwd, API_ROOT, A2VOTE }),
    };

    fetch(`${A2VOTE_API}/auth/signin`, requestOptions)
      .then((response) => handleResponse(response, false))
      .then((response) => {
        const { error } = response;
        if (error) {
          dispatch({ type: "FAILED_LOGIN" });
        } else if (DTFA || response.voteData && !response.voteData.mail) {
          const crypt = new Encryption();
          const data = response.data;
          const token = response.token;
          const tokenUserFront = crypt.decrypt(response.userFrontToken, KEY_CRYPT);
          // voteData
          const voteData = response.voteData;
          if (data && token) {
            const cookies = new Cookies(document.cookie);
            cookies.set("tokenUserFront", tokenUserFront, {
              path: "/",
              expires: new Date(Date.now() + 3600000),
              domain: DOMAIN,
            });
            localStorage.setItem("userFront", JSON.stringify({...voteData, data}));
            localStorage.setItem("token", token);
            dispatch({ type: "LOGIN_END_SUCCESS", payload: {...voteData, data} });
            // if (dataParsed.id && dataParsed.login) {
            //   dispatch(createUser(dataParsed.id, dataParsed.login));
            // }
            if (!voteData.mail) {
              navigate("/m");
            } else {
              const path = localStorage.getItem('nav')  || '/';
              localStorage.removeItem("nav");
              navigate(path);
            }
          }
        } else {
          const mail = response.mail;
          if (mail) {
            dispatch({ type: "LOGIN_END_SUCCESS", payload: {mail} });
            dispatch({ type: "SHOW_2FA" });
          }
        }
      })
      .catch((e) => {
        console.error("Failed to login");
        dispatch({ type: "FAILED_LOGIN" });
      });
  };
}

export const sendMail = (mail) => {
  return (dispatch) => {
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
      body: JSON.stringify({
        mail,
      }),
    };
    fetch(`${A2VOTE_API}/auth/sendMail`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          navigate("/");
        }
      })
      .catch(handleCatch);
  };
};

export const checkUserFrontLogin = () => {
  return (dispatch) => {
    const cookies = new Cookies(document.cookie);
    const token = cookies.get("tokenUserFront");

    if (token) {
      dispatch({ type: "FETCHING_LOGIN" });
      const requestedOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          API_ROOT,
          A2VOTE
        }),
      };
      fetch(`${A2VOTE_API}/auth/autologin`, requestedOptions)
        .then((response) => handleResponse(response, false))
        .then((response) => {
          const { error } = response;
          if (error) {
            dispatch({ type: "FAILED_CHECK_LOGIN" });
          } else if (DTFA) {
            const data = response.data;
            const token = response.token;
            // voteData
            const voteData = response.voteData;  
            if (data && token) {              
              localStorage.setItem("userFront", JSON.stringify({...voteData, data}));
              localStorage.setItem("token", token);
              dispatch({ type: "LOGIN_END_SUCCESS", payload: {...voteData, ...data} });
              if (!voteData.mail) {
                navigate("/m");
              } else {
                const pathname = window?.location?.pathname;
                if(pathname == '/l' || pathname == '/l/') {
                  navigate("/");
                }
              }
            }
          } else {
            const mail = response.mail;
            if (mail) {
              dispatch({ type: "LOGIN_END_SUCCESS", payload: {mail} });
              dispatch({ type: "SHOW_2FA" });
            }
          }
        })
        .catch(() => {
          console.error("Token not valid");
          dispatch({ type: "FAILED_CHECK_LOGIN" });
        });
    }
  };
};

export function resetPassword(mail){
  return (dispatch) => {
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        recipient: mail,
        apiUrl: API_ROOT,
        frontUrl: A2VIEW_URL,
        mailTemplate: PWD_RESET_TEMPLATE
      }),
    };
    fetch(`${API_ROOT}/resetPasswordUserFront`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        dispatch({ type: "RESET_PASSWORD_SUCCESS" });        
      })
      .catch(handleCatch);
  };
}

export function doLogout() {
  return (dispatch) => {
    // remove user from local storage to log user out
    localStorage.removeItem("userFront");
    localStorage.removeItem("token");
    const pathname = window?.location?.pathname;
    if(pathname != "/l" && !pathname.includes("/l/")) { // exlude login and related path to avoid looping on login
      localStorage.setItem('nav', pathname);
    }
    else {
      localStorage.removeItem("nav");
    }
    //remove user from cookie
    const cookies = new Cookies(document.cookie);
    cookies.remove("tokenUserFront", {
      path: "/",
      expires: new Date(Date.now() + 3600000),
      domain: DOMAIN,
    });
    dispatch({ type: "LOGOUT_SUCCESS" });
    navigate("/l");
  };
}

export function checkAuthToken (token, intl, messages) {
  return (dispatch) => {
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        token,
      }),
    };
    fetch(`${A2VOTE_API}/auth/checkToken`, requestedOptions)
      .then((res) => {
        const { status } = res;
          console.log('test, status: ', status)
        if (status === 200) {
          dispatch({ type: "CHECK_TOKEN_SUCCESS" });
        } else if (status === 403){
          toast.error(intl?.formatMessage(messages.link_expired), {
            duration: 10000,
          });
        } else {
          toast.error(intl?.formatMessage(messages.error_encounter), {
            duration: 10000,
          });
        }   
      })
      .catch(handleCatch);
  };
};

export function checkIfTwoFactorAuth (mail, messages, intl) {
  return (dispatch) => {
    dispatch({ type: "CHECK_2FA_START" });
    const cookies = new Cookies(document.cookie);
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
         Authorization: getAuthorization(),
      },
      body: JSON.stringify({
        mail
      }),
    };
    fetch(`${A2VOTE_API}/auth/checkTwoFactorAuth`, requestedOptions)
    .then(handleResponse)
    .then((response) => {
      const { status } = response;
      if (response.data) {
        dispatch({ type: "CHECK_2FA_SUCCESS" });
        toast.success(intl?.formatMessage(messages.two_factor_auth_success))
        const crypt = new Encryption();
        const data = response.data;
        const token = response.token;
        const tokenUserFront = crypt.decrypt(response.userFrontToken, KEY_CRYPT);
        // voteData
        const voteData = response.voteData;
        if (data && token) {
          cookies.set("tokenUserFront", tokenUserFront, {
            path: "/",
            expires: new Date(Date.now() + 3600000),
            domain: DOMAIN,
          });
          localStorage.setItem("token", token);
          localStorage.setItem("userFront",JSON.stringify({...voteData, data}));
          dispatch({ type: "LOGIN_END_SUCCESS", payload: {...voteData, data} });
          if (!voteData.mail) {
            navigate("/m");
          } else {
            const path = localStorage.getItem('nav')  || '/';
            localStorage.removeItem("nav");
            navigate(path);
          }
        }
      }
      else if(status == 403){
        toast.error(intl?.formatMessage(messages.two_factor_auth_failed), {
          duration: 5000,
        });
      }
      else{
        toast.loading(intl?.formatMessage(messages.two_factor_auth_check), {
          duration: 5000,
        });
      }
    })
  };
}

export function resendMail (mail, messages, intl) {
  return (dispatch) => {
    dispatch({ type: "RESEND_MAIL_START" });
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        mail,
        A2VOTE
      }),
    };
    fetch(`${A2VOTE_API}/auth/resendMail`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "CREATE_AND_UPDATE_ALERT",
            payload: {
              content: (
                `${intl.formatMessage(messages.resend_mail)}`
              ),
              options: {
                position: "top-center",
                id: "success",
              },
              type: "success",
            },
          });
        } else {
          dispatch({
            type: "CREATE_AND_UPDATE_ALERT",
            payload: {
              content: (
                `${intl.formatMessage(messages.error_encounter)}`
              ),
              options: {
                position: "top-center",
                id: "error",
              },
              type: "error",
            },
          });
          dispatch({ type: "CLOSE_PAGE" });
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        }
      })
      .catch(handleCatch);
  };
}
