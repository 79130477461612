//React - Redux
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// tools
import styled from "styled-components";
import theme from "theme";
//Utils
import Icon from "utils/icon";
// i18n
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
// actions
import { checkIfTwoFactorAuth, resendMail } from "actions/login";
//tools
import { io } from "socket.io-client";
import { A2VOTE_API } from "utils/config";
import {isMobile} from 'react-device-detect';

const PopupBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.3);

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`

const PopupContainer = styled.div`
  background: ${theme.white};
  width: 35%;
  height: 25%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'Raleway', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 96%;
    margin-top: 4%;
  }
`

const PopupTitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin: 0 40px;
  font-weight: bold;
  font-size: 1.5em;
  color: ${theme.dark};

  @media (max-width: 768px) {
    margin: 0 20px;
    font-size: 1.2em;
  }
`

const IconBackground = styled.div`
  width: 50px;
  height: 50px;
  background: ${theme.blue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const StyledIcon = styled(Icon)`
  filter: invert(100%);
`

const PopupTextDiv = styled.div`
  width: 80%;
  margin: 0 40px;

  @media (max-width: 768px) {
    margin: 0 20px;
  }
`

const ButtonDiv = styled.div`
  margin: 0 40px;
  color: ${theme.blue};

  @media (max-width: 768px) {
    margin: 0 20px;
  }
`

const StyledButton = styled.button`
  background: ${theme.blue};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.white};
  border: none;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  justify-content: space-around;
  width: 180px;
  font-size: 1em;
  margin-top: 10px;
  outline: none;

  &:disabled {
    background: ${theme.gray};
    cursor: not-allowed;
  }
`

const BoldMail = styled.span`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-top: 10px;
`;

const CountdownSpan = styled.span`
  color: ${theme.blue};
  font-weight: bold;
  margin-left: 10px;
`

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledButtonClose = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${theme.red};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${theme.white};
  cursor: pointer;
  outline: none;
  border: none;
`

const PopupHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const CloseDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5%;
`

const Popup = ({}) => {

  const dispatch = useDispatch();
  const [seconde, setSeconde] = useState(59);
  const { userFront, twoAuth } = useSelector(state => state.user);
  const verify2FA = (mail, userFront, messages, intl) => dispatch(checkIfTwoFactorAuth(mail, messages, intl));
  const resend = (mail, messages, intl) => dispatch(resendMail(mail, messages, intl));
  const intl = useIntl();
  const [socket] = useState(() => io(`${A2VOTE_API}`));

  useEffect(() => {
    if(isMobile) {
      let interval = null;
      interval = setInterval(() => {
        verify2FA( userFront.mail, userFront, messages, intl);
        }, 5000);
      return () => clearInterval(interval);
    }
    socket.on('confirmed', (socketId) => {
      if(socketId === socket.id) {
        verify2FA( userFront.mail, userFront, messages, intl);
      }
    });
    return () => {
      socket.off('confirmed');
    }
  }, []);

  useEffect(() => {
    let interval = null;
    if (seconde > 0 && twoAuth) {
      interval = setInterval(() => {
      setSeconde(seconde => seconde - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconde]);

  useEffect(() => {
    if (!twoAuth) {
      setSeconde(59);
    }
  }, [twoAuth]);

  const handleResend = () => {
    resend(userFront.mail, messages, intl)
    setSeconde(59);
  }

  const handleOnClick = () => {
    dispatch({type: "CLOSE_PAGE"})
  }

  return (
    <PopupBackground>
      <PopupContainer>
        <PopupHead>
          <PopupTitleDiv>
            <IconBackground>
              <StyledIcon icon="InterFaceLock" />
            </IconBackground>
              <FormattedMessage {...messages.two_factor_auth} />
          </PopupTitleDiv>
          <CloseDiv>
            <StyledButtonClose onClick={(e) => handleOnClick(e)}>
              <StyledIcon icon="Cross" size="sm" class="close"/>
            </StyledButtonClose>
          </CloseDiv>
        </PopupHead>
        <PopupTextDiv>
          <FormattedMessage {...messages.two_factor_auth_confirm} /> <BoldMail> {userFront.mail} </BoldMail>
        </PopupTextDiv>
        <ButtonDiv>
          <RowDiv>
            {!seconde ?
              <StyledButton onClick={() => handleResend()} disabled={seconde != 0 ? true : false}>
                <StyledIcon icon="MailSendEmail" size="sm"/>
                <FormattedMessage {...messages.two_factor_auth_resend} />
              </StyledButton>
            :
              <CountdownSpan>{seconde != 0 && seconde}  {seconde != 0 && (seconde > 10 ? <FormattedMessage {...messages.secondes} /> : <FormattedMessage {...messages.seconde} />)} <FormattedMessage {...messages.resend_mail_wait} /> </CountdownSpan>
            }
          </RowDiv>
        </ButtonDiv>
      </PopupContainer>
    </PopupBackground>
  );
}

export default Popup;
